<template>


    <div class="generateIdCardOuterContainer">

      <div id="my-node2" class="generateIdCardContainer" v-if="volunteersData">

        <div class="idCardNameContainer">
          <div class="volunteerByGenderLabel">
            {{ volunteersData.gender === 'female' ? 'Prostovoljka' : 'Prostovoljec' }}
          </div>
          <div class="volunteerName">{{ volunteersData.first_name + ' ' + volunteersData.last_name }}</div>
        </div>

        <div class="idCardImageContainer">
          <img :src="$globalFunctions.getImage(volunteersData.profile_image)" style="border-radius: 50%"/>
        </div>


        <div class="idCardUnitName">
          <div class="unitName">{{ volunteersData.units.name }}</div>
          <div class="volunteerName">{{ volunteersData.first_name + ' ' + volunteersData.last_name }}</div>
        </div>

        <div class="idCardCode">
          <vue-qrcode :quality="1" :margin="0" :scale="10" style="width: 220px;" :value="getVoluntersId" class="my-3"/>
          <!--          <qrcode ng-if="ctrl.idCardVolunteerCode" data="{{volunteersData.idCardVolunteerCode}}" version="2"-->
          <!--                  error-correction-level="Q" size="440" color="#000" background="#fff"></qrcode>-->
          <div class="codeNumber">{{ getVoluntersId }}</div>
        </div>

      </div>

    </div>

<!--    {{ volunteersData }}-->

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'IdCard',

  components: {
    VueQrcode
  },
  data() {
    return {
      volunteersData: null

    }
  },

  computed: {
    getVoluntersId() {
      return '04042014' + this.pad(this.volunteersData.unit_id, 3) + this.pad(this.volunteersData.id, 4)
    }
  },

  mounted() {
    const _this = this;

    _this.$vs.loading();

    console.log(Vue.prototype.$http.defaults.headers);
    console.log("this.$route.query", this.$route.query);

    if (_this.$route.params.volunteerId) {
      if (this.$route.query.jwt) {
        Vue.prototype.$http.interceptors.request.use(
          config => {
            const token = this.$route.query.jwt;
            if (token) {
              if (config.url.includes('https://api.sopotniki.org/')) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
              } else {
                config.headers.common["Authorization"] = token;
              }
            }
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );

      }


      _this.getVolunteerData();


    } else {
      _this.$vs.loading.close();
    }
  },


  methods: {
    async getVolunteerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.$route.params.volunteerId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}
@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateIdCardOuterContainer {
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  background: white;
  display: block;
  margin-bottom: 1cm !important;
  position: relative;
  width: 2480px;
  height: 1600px;
  font-family: 'Open Sans' !important;

  .generateIdCardContainer {
    background: url('~@/assets/images/content/business_letters/id_card.jpg') top left no-repeat;
    background-size:contain;
    width: 2480px;
    height: 3508px;
    position: relative;
    overflow: hidden;
    transform: scale(0.45, 0.45);
    top: -1000px;
    left: -650px;

    .idCardNameContainer {
      position: absolute;
      top: 1212px;
      left: 1211px;

      .volunteerByGenderLabel {
        font-size: 31px;
        color: #9cb1db;
        letter-spacing: 0.5px;
      }

      .volunteerName {
        font-size: 45px;
        margin-top: 9px;
        color: #000000;
        font-weight: 500;
      }

    }

    .idCardImageContainer {
      position: absolute;
      top: 1099px;
      left: 814px;

      img {
        width: 326px;
        height: 326px;
      }
    }

    .idCardUnitName {
      position: absolute;
      top: 2158px;
      left: 807px;

      .unitName {
        font-size: 26px;
        color: #9cb1db;
        letter-spacing: 0.5px;
      }

      .volunteerName {
        font-size: 26px;
        color: #9cb1db;
        letter-spacing: 0.5px;
      }

    }

    .idCardCode {
      position: absolute;
      top: 2295px;
      left: 807px;

      qrcode canvas {
        width: 220px;
        height: 220px;
      }

      .codeNumber {
        width: 220px;
        text-align: center;
        color: #000000;
        font-size: 20px;
        letter-spacing: 0.5px;
      }

    }
  }

}
</style>
